import { render, staticRenderFns } from "./default.vue?vue&type=template&id=66b0885a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilesPreview: require('/codebuild/output/src1425592139/src/client/components/FilesPreview.vue').default})
